import React, { useState, useEffect } from "react";
import one from "../../images/one.png";
import two from "../../images/two.png";
import three from "../../images/three.png";
import four from "../../images/four.png";
import wishlist from "../../images/wishlist.png";
import star from "../../images/star.png";
import clock from "../../images/clock.png";
import checkmark from "../../images/checkmark.png";
import { Col, Container, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./index.css";
import { Button } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { falconApiCalling } from "../API/falconApiCalling";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function Index(props) {
  const [product, setProduct] = useState([
    {
      id: 1,
      image: one,
      title: "Mega Dhow Cruise In Marina",
      sub_title: "Dinner Cruise | Marina",
      charges: "AED 99.00",
      rating: "4.7 (108)",
      time: "2 Hours",
      terms: [
        { id: 1, title: "Free Cancellation" },
        { id: 2, title: "Trending" },
      ],
    },
    {
      id: 2,
      image: two,
      title: "Evening Desert Safari",
      sub_title: "Desert Safari | Dubai",
      charges: "AED 98.00",
      rating: "4.7 (108)",
      time: "5 Hours",
      terms: [
        { id: 1, title: "Free Cancellation" },
        { id: 2, title: "Trending" },
      ],
    },
    {
      id: 3,
      image: three,
      title: "Dubai Half Day City Tour",
      sub_title: "City Tour | Dubai",
      charges: "AED 98.00",
      rating: "4.7 (108)",
      time: "5 Hours",
      terms: [
        { id: 1, title: "Free Cancellation" },
        { id: 2, title: "Trending" },
      ],
    },
    {
      id: 4,
      image: four,
      title: "Sunset Dhow Cruise",
      sub_title: "Dinner Cruise | Dubai",
      charges: "AED 98.00",
      rating: "4.7 (108)",
      time: "5 Hours",
      terms: [
        { id: 1, title: "Free Cancellation" },
        { id: 2, title: "Trending" },
      ],
    },
  ]);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    console.log(rest);
    return (
      <>
        <BrowserView>
          <div className="carousel-button-group-product">
            <BrowserView>
              {" "}
              <Button
                className={currentSlide === 0 ? "disable" : "enable"}
                onClick={() => previous()}
              >
                {"<"}
              </Button>
              <Button className="enable" onClick={() => next()}>
                {">"}
              </Button>
            </BrowserView>
            <MobileView>
              {" "}
              <Button
                className={
                  currentSlide === 0 ? "disable_mobile" : "enable_mobile"
                }
                onClick={() => previous()}
              >
                {"<"}
              </Button>
              <Button className="enable_mobile" onClick={() => next()}>
                {">"}
              </Button>
            </MobileView>
          </div>
        </BrowserView>
        <MobileView>
          <div className="carousel-button-group-product_mobile">
            <MobileView>
              {" "}
              <Button
                className={
                  currentSlide === 0 ? "disable_mobile" : "enable_mobile"
                }
                onClick={() => previous()}
              >
                {"<"}
              </Button>
              <Button className="enable_mobile" onClick={() => next()}>
                {">"}
              </Button>
            </MobileView>
          </div>
        </MobileView>
      </>
    );
  };
  const [terms, setTerms] = useState([
    {
      id: "1",
      title: "Trending ",
    },
    {
      id: "2",
      title: "Instant confirmation ",
    },
  ]);

  const getDinner = () => {
    if (
      props.title === "Dinner Cruise Offers" ||
      props.title === "Top Offer" ||
      props.title === "Most Selling"
    ) {
      let params = { action: "GET_DINNER_CRUISE" };
      falconApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setProduct(res);
        }
      });
    } else if (props.title === "Desert Safari Offers") {
      let params = { action: "GET_DESERT_SAFARI_OFFER" };
      falconApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setProduct(res);
        }
      });
    } else if (props.title === "Trending 2024") {
      let params = { action: "GET_TRENDING_PRODUCT" };
      falconApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setProduct(res);
        }
      });
    } else if (props.title === "Happy New Year") {
      let params = { action: "GET_NEW_YEAR_PRODUCT" };
      falconApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setProduct(res);
        }
      });
    }
  };

  useEffect(() => {
    getDinner();
  }, []);

  return (
    <Container className="product-category-container">
      <div>
        <h1>{props.title}</h1>
        <BrowserView>
          <p>{props.description}</p>
        </BrowserView>
        <MobileView>
          {/* <p style={{ width: "75%" }}>{props.description}</p> */}
        </MobileView>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container-product"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        itemClass="carousel-item-padding-10-px"
        customButtonGroup={<ButtonGroup />}
      >
        {product.map((item) => {
          return (
            <div
              md={4}
              className="item-container-product"
              style={{ padding: "10px", height: "100%" }}
            >
              <div className="inner_product">
                <a
                  href={"/product/" + item.id}
                  style={{ textDecoration: "none", padding: "10px" }}
                >
                  <div className="item-image-container">
                    <Image
                      src={item.image}
                      className={"item-image"}
                      style={{ borderRadius: "10px", marginTop: "-20px" }}
                    />
                  </div>
                  <Row style={{ marginTop: "8px" }}>
                    <Col md={10}>
                      <h4 className="item-product-name">{item.name}</h4>
                      <span className="item-product-subtitle">
                        {item.sub_title}
                      </span>
                    </Col>
                    {/* <Col md={1}>
                  <Image src={wishlist} className={"wishlist-icon"} />
                </Col> */}
                  </Row>
                  <Row style={{ marginTop: "8px" }}>
                    <Col md={9}>
                      <span className="item-product-subtitle">from</span>
                      <h4 className="item-product-name">
                        {" "}
                        AED {item.charges}.00/-
                      </h4>
                      <span
                        className="item-product-subtitle"
                        style={{ marginTop: "-20px" }}
                      >
                        *Price varies
                      </span>
                    </Col>
                    <Col md={3}>
                      <Image src={star} className={"star-icon"} />
                      <span className="item-product-subtitle">
                        {item.rating}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <span>
                      <Image
                        src={clock}
                        style={{ width: "15px", height: "15px" }}
                      />
                      {"  "}
                      <span className="item-product-subtitle">
                        {" "}
                        {item.time}
                      </span>
                    </span>
                    <div style={{ flexDirection: "row" }}>
                      {terms.map((item) => {
                        return (
                          <span style={{ marginLeft: "0px" }}>
                            <Image
                              src={checkmark}
                              style={{ width: "12px", height: "8px" }}
                            />
                            {"  "}
                            <span className="item-product-subtitle">
                              {" "}
                              {item.title}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </Carousel>
    </Container>
  );
}
