import React from "react";
import category from "../../images/category.jpg";
import { Container } from "react-bootstrap";
import { Breadcrumb } from "antd";
import dessert from "../../images/ds.jpeg";
import dinner from "../../images/dinner.jpeg";
import city_tour from "../../images/city_tour.jpeg";
import trio from "../../images/trio.jpeg";
import combo from "../../images/combo.jpeg";
import activity from "../../images/activity.jpeg";
import most from "../../images/most.jpeg";
import top from "../../images/top-offer.jpeg";
import "./index.css";
export default function Index(props) {
  const getBackground = () => {
    if (
      props.title.replace("-", " ") === "Desert Safari" ||
      props.title.replace("%20", " ") === "Desert Safari"
    ) {
      return `url(${dessert})`;
    } else if (
      props.title.replace("-", " ") === "Dinner Cruises" ||
      props.title.replace("%20", " ") === "Dinner Cruises"
    ) {
      return `url(${dinner})`;
    } else if (
      props.title.replace("-", " ") === "City Tours" ||
      props.title.replace("%20", " ") === "City Tours"
    ) {
      return `url(${city_tour})`;
    } else if (
      props.title.replace("-", " ") === "Trio Offers" ||
      props.title.replace("%20", " ") === "Trio Offers"
    ) {
      return `url(${trio})`;
    } else if (
      props.title.replace("-", " ") === "Combo Offers" ||
      props.title.replace("%20", " ") === "Combo Offers"
    ) {
      return `url(${combo})`;
    } else if (
      props.title.replace("-", " ") === "Activities" ||
      props.title.replace("%20", " ") === "Activities"
    ) {
      return `url(${activity})`;
    } else if (
      props.title.replace("-", " ") === "Most Selling" ||
      props.title.replace("%20", " ") === "Most Selling"
    ) {
      return `url(${most})`;
    } else if (
      props.title.replace("-", " ") === "Top Offer" ||
      props.title.replace("%20", " ") === "Top Offer"
    ) {
      return `url(${top})`;
    }
  };

  return (
    <div>
      <Container
        fluid
        className="section_background"
        style={{
          backgroundImage: getBackground(),
        }}
      ></Container>
      <Container className="section_transparent" fluid>
        <Container
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="title-transparent">
              {props.title.replace("%20", " ")}
            </h3>
          </div>
        </Container>
      </Container>
    </div>
  );
}
