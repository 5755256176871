import React, { useEffect, useState } from "react";
import Header from "../../Component/Header/index";
import ProductBanner from "../../Component/ProductBanner/index";
import ProductDetail from "../../Component/ProductDetail/index";
import ProductDescription from "../../Component/ProductDescription/index";
import Footer from "../../Component/Footer/index";
import ProductCalculator from "../../Component/ProductCalculator/index";
import TalkToExpert from "../../Component/TalkToExpert";
import "./Product.css";
import { Col, Container, Row } from "react-bootstrap";
import { MobileView } from "react-device-detect";
import { Button } from "antd";
export default function Index() {
  const [bottomPrice, setBottomPrice] = useState("0");
  return (
    <Container fluid>
      <Header />
      <ProductBanner />
      <Container className="product-main-container">
        <Row>
          <Col md={8}>
            <ProductDetail />
            <ProductDescription />
          </Col>
          <Col md={4}>
            <ProductCalculator myBottom={setBottomPrice} />
            <TalkToExpert />
          </Col>
        </Row>
      </Container>
      <MobileView>
        <Container fluid className="sticky_product">
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <a
                href={"#book_now"}
                type="primary"
                className="check_availability"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
                // onClick={() => checkAvailabilty()}
              >
                Book Now
              </a>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <h2 className="discounted_price_left text-left">
                AED {bottomPrice}.00/-
              </h2>
            </Col>
          </Row>
        </Container>
      </MobileView>
      <Footer />
    </Container>
  );
}
